import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import style from '../../modules/Formdetails.module.css';
import emailjs from '@emailjs/browser';

function Formdetails() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contact: '',
    company: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    const templateParams = {
      from_name: formData.name,
      from_email: formData.email,
      contact_number: formData.contact,
      company: formData.company
      
      
    };

    emailjs.send('service_gx0vpji', 'template_1buu9s7', templateParams, 'HvYc1Z8qYrBXBSnfk')
      .then((result) => {
        console.log('Email successfully sent!', result.text);
        setFormData({ name: '', email: '', contact: '', company: '' });
      }, (error) => {
        console.log('Failed to send email:', error.text);
      });
  };

  return (
    <>
      <section>
        <form onSubmit={sendEmail}>
          <div className={style.formdetails1}>
            <h4 className='pb-5'>Sign up for our newsletter to get updated information, news, or insight.</h4>

            <Container>
              <Row>
                <Col md={3}>
                  <div className="mb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                  </div>
                </Col>
                <Col md={3}>
                  <div className="mb-3">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      placeholder="Email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </div>
                </Col>
                <Col md={3}>
                  <div className="mb-3">
                    <input
                      type="tel"
                      className="form-control"
                      name="contact"
                      placeholder="Contact"
                      value={formData.contact}
                      onChange={handleChange}
                    />
                  </div>
                </Col>
                <Col md={3}>
                  <div className="mb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="company"
                      placeholder="Company name"
                      value={formData.company}
                      onChange={handleChange}
                    />
                  </div>
                </Col>
              </Row>
            </Container>

            <div className={style.container}>
              <button type="submit" className="btn btn-primary">Sign up</button>
            </div>
          </div>
        </form>
      </section>
    </>
  );
}

export default Formdetails;
