import { useEffect } from 'react';
import './App.css';
// import About from './components/About/About';
import Header from './components/Header/Navbar';
import AOS from 'aos';
import 'aos/dist/aos.css';
import HomeScreen from './screen/HomeScreen';
import { Route, Routes, useLocation, Router, Navigate } from 'react-router-dom';
import AboutScreen from './screen/AboutScreen';
import Contact from './screen/ContactScreen';
import Service from './screen/Service';
import TDSreturnfiling from './components/Service/TDSreturnfiling';
import Auditingservices from './components/Service/Auditingservices';
import Completebookkeeping from './components/Service/Completebookkeepingservices';
import GSTRegistration from './components/Service/GSTRegistration ';
import Trademarkregistrationservices from './components/Service/Trademarkregistrationservices';
import RegisterPatner from './components/Service/RegisterPatner';
import Digitalsignature from './components/Service/Digitalsignatureservices';
import Udyamregistration from './components/Service/Udyamregistration';
import Incometaxs from './components/Service/Incometax';
import CompanyRegistration from './components/Service/CompanyRegistration ';
import Footer from './components/FooterPage/Footer';
function App() {

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-out',
    });
  }, []);

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
      
      <>
        <Header />
        <Routes>
          <Route path="/" element={<HomeScreen />} />
          <Route path="/about" element={<AboutScreen />} />
          <Route path="/service" element={<Service />} />
          <Route path="/contact" element={<Contact />} />

          {/* 11 services */}
          <Route path="/company-registration" element={<CompanyRegistration /> } />
          <Route path="/income-tax-filing" element={<Incometaxs />} />
          <Route path="/gst-registration" element={<GSTRegistration />} />
          <Route path="/bookkeeping-accounting" element={<Completebookkeeping />} />
          <Route path="/auditing-services" element={<Auditingservices />} />
          <Route path="/tds-filing" element={<TDSreturnfiling />} />
          <Route path="/trademark-registration" element={<Trademarkregistrationservices />} />
          <Route path="/partnership-registration" element={<RegisterPatner />} />
          <Route path="/digital-signature" element={<Digitalsignature />} />
          <Route path="/udyam-registration" element={<Udyamregistration />} />
          {/* 11 services */}



          {/* Redirection url */}
        <Route path="service/company-registration" element={<Navigate to="/company-registration" replace />} />
        <Route path="service/income-tax-filing" element={<Navigate to="/company-registration" replace />} />
        <Route path="service/gst-registration" element={<Navigate to="/company-registration" replace />} />
        <Route path="service/bookkeeping-accounting" element={<Navigate to="/company-registration" replace />} />
        <Route path="service/auditing-services" element={<Navigate to="/company-registration" replace />} />
        <Route path="service/tds-filing" element={<Navigate to="/company-registration" replace />} />
        <Route path="service/trademark-registration" element={<Navigate to="/company-registration" replace />} />
        <Route path="service/partnership-registration" element={<Navigate to="/company-registration" replace />} />
        <Route path="service/digital-signature" element={<Navigate to="/company-registration" replace />} />
        <Route path="service/udyam-registration" element={<Navigate to="/company-registration" replace />} />
      

        </Routes>
        <Footer/>
      </>
  );
}

export default App;
