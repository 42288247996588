import React from 'react'
import Contact from '../components/Contact/Contactpage'
import Contactpage from '../components/Contact/Contactpage'

const ContactScreen = () => {
  return (
    <>
      <Contactpage/>
    </>
  )
}

export default ContactScreen
