import { Container} from 'react-bootstrap';
import './Service.css'
// import { TiTick } from "react-icons/ti";
import { Link } from 'react-router-dom';
import Formdetails from '../Form/Formdetails';


function Incometaxs() {
    return (
      <>
        <section className="incometax">
            <div className='service2'>
           <p>Tax India Tax</p>
           <h1>Income Tax Filing Services</h1>
           <p>Avoid penalties and ensure compliance with our efficient filing services.</p>
           <Link to='/service'><button type="button" className="btn btn-primary btn-lg">Discover More</button></Link>

           </div>
        </section>
        
        <div style={{backgroundColor:'rgb(245, 248, 248)'}}>
       <Container>
       <section className='service3' >
            <div className='service4'>
            <h2>What We Do</h2>
            <br></br>
          <p>Timely and accurate filing of income tax returns is crucial for both individuals and businesses. With our specialist support every aspect of your tax submission is handled. By filing early, we help reduce fewer errors and mitigated the risk of penalties. We provide clear guidance on all tax forms to optimize your returns and stay compliant.
          </p>
          <div className='ps-0 ps-sm-5'>
          <p className='customParagraph'>We help to file ITR1, ITR2, ITR3, ITR4, ITR5, ITR6, ITR7 and e-pay tax.</p>
           <p className='customParagraph'>We provide solutions to income tax notifications and issues.</p>
           <p className='customParagraph'>Early filing helps avoid errors and technical glitches.</p>
           <p className='customParagraph'>We simplify and expedite the ITR e-filing process.</p>
           <p className='customParagraph'>We ensure a fast, secure and efficient tax filing experience.</p>
           <p className='customParagraph'>Get professional support throughout the filing process for a hassle-free experience.</p>
          </div>
           
            </div>
            <div className='service5'>
            <h2>Secure Your Business Future with Ease!</h2>
           <p>Don’t let paperwork slow you down. Discover stress-free management solutions.</p>
            <Link to='/contact'> <button type="button" className="btn btn-primary btn-lg">Contact Us</button> </Link>
            </div>
        </section>
       </Container>
        
       <br />  <br />
        </div>
        
          <Formdetails />
      </>
      
    );
  }
  
  export default Incometaxs;