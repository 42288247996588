import React from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import "../../modules/Faquestion.css";

const Faquestion = () => {
  return (
    <>
      <section className="mt-5">
        <div className="pt-4"></div>
        <section className='faqbg mt-5'>
          <Container className="py-5">
            <div className="pb-4">
              <h2>Frequently Asked Questions</h2>
            </div>
            <Row className="px-0 px-md-5 mx-0 mx-lg-5">
              <Col md={12}>
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0" className="my-3">
                    <Accordion.Header className="accheading">
                      <p className="mb-0">What types of companies can be registered?</p>
                    </Accordion.Header>
                    <Accordion.Body >
                      Any type of business entity, including sole
                      proprietorships, partnerships, LLPs, and private/public
                      limited companies, can be registered. The registration
                      process can take anywhere from a few days to a couple of
                      weeks, depending on the type of registration and
                      documentation completeness.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="1"  className="my-3">
                    <Accordion.Header  className="accheading">
                    <p className="mb-0">What is GST registration, and why is it important?</p>
                    </Accordion.Header>
                    <Accordion.Body>
                      GST registration is essential for businesses exceeding
                      certain turnover limits. It ensures compliance with tax
                      laws, allows tax credits, and protects businesses against
                      legal issues.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="2" className="my-3">
                    <Accordion.Header className="accheading">
                    <p className="mb-0"> What are the advantages of Udyam registration?</p>
                    </Accordion.Header>
                    <Accordion.Body>
                      Benefits include access to government schemes, easier
                      funding options, and recognition as an MSME, providing a
                      competitive edge.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3" className="my-3">
                    <Accordion.Header className="accheading">
                    <p className="mb-0">What documents are required for company registration?</p>
                    </Accordion.Header>
                    <Accordion.Body>
                      Documents generally include identification proof, address
                      proof, and any business specific documents that may be
                      required based on the type of entity. Businesses can
                      change their registration type by following the necessary
                      legal.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4" className="my-3">
                    <Accordion.Header className="accheading">
                    <p className="mb-0"> What can I expect from a statutory audit?</p>
                    </Accordion.Header>
                    <Accordion.Body>
                      A statutory audit assesses financial statements to ensure
                      they accurately reflect the company’s financial position
                      while ensuring compliance with regulations.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="5" className="my-3">
                    <Accordion.Header className="accheading">
                    <p className="mb-0"> What is a Digital Signature Certificate (DSC)?</p>
                    </Accordion.Header>
                    <Accordion.Body>
                      A DSC is a secure electronic version of a signature, used
                      to authenticate documents and facilitate digital
                      transactions. Digital signatures eliminate the need for
                      physical signatures, streamlining document transactions
                      while ensuring security.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="6" className="my-3">
                    <Accordion.Header className="accheading">
                    <p className="mb-0">What is bookkeeping, and why is it essential for my
                      business? </p>
                    </Accordion.Header>
                    <Accordion.Body>
                      Bookkeeping involves maintaining accurate financial
                      records, which is vital for making informed decisions,
                      ensuring regulatory compliance and allows business owners
                      to focus on growth strategies.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="7" className="my-3">
                    <Accordion.Header className="accheading">
                    <p className="mb-0"> Why should I register my trademark?</p>
                    </Accordion.Header>
                    <Accordion.Body>
                      Trademark registration protects your brand identity and
                      provides legal recourse against unauthorized use.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            </Row>
          </Container>
        </section>
      </section>
    </>
  );
};

export default Faquestion;
