import { Container} from 'react-bootstrap';
import './Service.css'
// import { TiTick } from "react-icons/ti";
import { Link } from 'react-router-dom';
import Formdetails from '../Form/Formdetails';
// import { TbMessageQuestion } from "react-icons/tb";

function RegisterPatner() {
    return (
      <>
        <section className="patnership">
            <div className='service2'>
           <p>Tax India Tax</p>
           <h1>Partnership Deed Formation and Registration</h1>
           <p>Formalize your business relationships with our hassle-free partnership registration services.</p>
           <Link to='/service'><button type="button" className="btn btn-primary btn-lg">Discover More</button></Link>

           </div>
        </section>
       
        <div style={{backgroundColor:'rgb(245, 248, 248)'}}>
       <Container>
       <section className='service3' >
            <div className='service4'>
            <h2>What We Do</h2>
            <br></br>
           <p>Forming a partnership enables collective business growth, where two or more parties share risks and rewards. Our partnership deed governs the relationship, ensuring clarity and mutual understanding amongst partners. Registration partnership with us, solidifies the agreement and provides legal advantages, securing the partnership's framework.</p>
           <div className='ps-0 ps-sm-5'>
           <p className='customParagraph'>We help in drafting the partnership deed, which is a formal agreement between the partners.</p>
           <p className='customParagraph'>We register the partnership deed in the relevant government portal to ensure legal recognition.</p>
           <p className='customParagraph'>We help establish the partnership firm through the agreement mentioned in the partnership deed.</p>
           <p className='customParagraph'>We regulate the relationship between shareholders and partnership firms through deed.</p>
           <p className='customParagraph'>We help engage in any trade, business or profession as defined by the partnership agreement.</p>
           </div>
            </div>
            <div className='service5'>
            <h2>Secure Your Business Future with Ease!</h2>
           <p>Don’t let paperwork slow you down. Discover stress-free management solutions.</p>
            <Link to='/contact'> <button type="button" className="btn btn-primary btn-lg">Contact Us</button> </Link>
            </div>
        </section>
       </Container>
        <br />  <br />
        
        </div>
       
          <Formdetails />
      </>
      
    );
  }
  
  export default RegisterPatner;