import React from "react";
import style from "../../modules/About.module.css";



const About = () => {
  return (
    <>
      <section>
        <div className={` ${style.mainrow1}`}>
          <div className={`${style.mainrow2}`}>
            <h1 className={`${style.boldHeading}`}>About Us</h1>
          </div>
        </div>
       
      </section>
     
    
    </>
  );
};

export default About;
