import React from 'react'
import Testimonials from "../components/Testimonials/Testimonials";
import HomeOurService from '../components/HomeOurService/HomeOurService';
import SingleServiceHero from '../components/SingleService/SingleServiceHero';
import Faquestion from '../components/Faquestion/Faquestion';
import Formdetails from '../components/Form/Formdetails';

const Service = () => {
  return (
    <>
      <SingleServiceHero />
      <HomeOurService />
      <Testimonials />
      <Faquestion />
      <Formdetails />
    </>
  )
}

export default Service
