import { Container} from 'react-bootstrap';
import './Service.css'
// import { TiTick } from "react-icons/ti";
import { Link } from 'react-router-dom';
import Formdetails from '../Form/Formdetails';

function GSTRegistration () {
    return (
      <>
        <section className="Gstregistration">
            <div className='service2'>
           <p>Tax India Tax</p>
           <h1>GST Registration and Filing Services</h1>
           <p>Navigate the complexities of GST with our comprehensive support.
           </p>
           <Link to='/service'><button type="button" className="btn btn-primary btn-lg">Discover More</button></Link>

           </div>
        </section>
        
        <div style={{backgroundColor:'rgb(245, 248, 248)'}}>
       <Container>
       <section className='service3' >
            <div className='service4'>
            <h2>What We Do</h2>
            <br></br>
           <p>By simplifying GST compliance, we ensure proper management of your tax obligations. With end-to-end services, you no need to handle GST complexities solo. Having a GST identification number for your business opens up avenues for specific exemptions and maintaining legitimacy in the market. Our streamlined e-filing procedures ensure timely compliance.
           </p>
           <div className="ps-0 px-sm-5">
            <p className='customParagraph'>We handle GSTR-1, GSTR-3B, GSTR-99C10 filings, E-way bill generation, and GST Registration applications.</p>
            <p className='customParagraph'>We ensure your GST Identification Number is securely processed.</p>
            <p className='customParagraph'>A dedicated manager will oversee and process your GST registration from start to finish.</p>
            <p className='customParagraph'>Enjoy a hassle-free experience with our completely online services—no need to visit the office.</p>
            <p className='customParagraph'>We save your time and ensure proper GST account maintenance with our efficient, fully online GST services.</p>
            </div>
            </div>
            <div className='service5'>
            <h2>Secure Your Business Future with Ease!</h2>
           <p>Don’t let paperwork slow you down. Discover stress-free management solutions.</p>
            <Link to='/contact'> <button type="button" className="btn btn-primary btn-lg">Contact Us</button> </Link>
            </div>
        </section>
       </Container>
       <br />  <br />
        
        </div>
        
        
        <Formdetails />
      </>
      
    );
  }
  
  export default GSTRegistration ;