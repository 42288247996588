import React from 'react'
import About from '../components/About/About'
import HomeAccounting from '../components/HomeAccounting/HomeAccounting'
import OurHistory from '../components/Ourhistory/OurHistory'
import Achievements from '../components/Achievements/Achievements'
import Callbackabout from '../components/Call-Back-AboutPage/Callbackabout'
import Purposegoals from '../components/Purposeandgoals/Purpose-goals'
import Formdetails from '../components/Form/Formdetails'

const AboutScreen = () => {
  return (
    <>
      <About/>
      <HomeAccounting/>
      <OurHistory/>
      <Purposegoals/>
      <Achievements/>
      <Callbackabout/>
      <Formdetails />
    
    </>
  )
}

export default AboutScreen
