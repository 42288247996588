import React from "react";
import style from "../../modules/Footer.module.css";
import logo from '../../assets/logo/logo1.png'
import { Col,  Row,Container } from 'react-bootstrap'
// import Formdetails from "../Form/Formdetails";
import Contactinformation from "../Contactinformation/Contactinformation";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <>
    {/* <Formdetails /> */}
    <Contactinformation />
      <footer className={style.footer}>
       <Container>
       <div className={style.container}>
        <Row>
            <Col lg={3} md={6} sm={12}  xl={3} xxl={3}>
              <div className={style["footer-col1"]}>
                <h4>Head office</h4>
                <ul>
                  <li> Damas Building <br/>  Al Maktoum Road<br/>  Dubai, UAE</li>
                </ul>
              </div>
            </Col>
            <Col lg={3} md={6} sm={12}  xl={3} xxl={3}>
              <div className={style["footer-col2"]}>
                <h4>Our Branches</h4>
                <ul>
                  <li>Stew&Jones Building <br/> Tvm-Ngl Main Road <br/> Marthandam, India</li>
                </ul>
              </div>
            </Col>
            <Col lg={3} md={6} sm={12}  xl={3} xxl={3}>
              <div className={style["footer-col3"]}>
                <h4>Service</h4>
                <ul>
                  <li><Link to="/income-tax-filing">Tax Filings</Link></li>
                  <li><Link to="https://shadobooks.com/accounting-erp-software">Accounting</Link></li>
                  <li><Link to="/auditing-services">Auditing</Link></li>
                  <li><Link to="/gst-registration">GST filing</Link></li>
                </ul>
              </div>
            </Col>
            <Col lg={3} md={6} sm={12} xl={3} xxl={3}>
              <div className={style["footer-col4"]}>
                <h4>Quick Links</h4>
                <ul>
                  <li><Link to="/service">FAQ</Link></li>
                  <li><Link to="/about">About Us</Link></li>
                  <li><Link to="/contact">Contact Us</Link></li>
                  <li><Link to="/service">Testimonials</Link></li>
                </ul>
              </div>
            </Col>
          </Row>
         
          <div className={style["footer-bottom"]}>
            <Row className={style["footer-bottom-row"]}>
              <Col lg={1} md={6} sm={12} xl={12} xxl={2} className={style["footer-logo"]}>
                <img src={logo} alt="logo" style={{ width: '200px' }} />
              </Col>
              <Col lg={7} md={6} sm={12} xl={12} xxl={6}className={style["footer-text"]}>
                <p>Site Under StewandJones Technologies</p>
              </Col>
            </Row>
          </div>
        </div>
       </Container>
      </footer>
    </>
  );
};

export default Footer;
