import { Container} from 'react-bootstrap';
import './Service.css'
// import { TiTick } from "react-icons/ti";
import { Link } from 'react-router-dom';
import Formdetails from '../Form/Formdetails';


function Completebookkeeping() {
    return (
      <>
        <section className="bookkeeping">
            <div className='service2'>
           <p>Tax India Tax</p>
           <h1>Complete Bookkeeping Services</h1>
           <p>Keep your finances organized with our reliable bookkeeping services.</p>
           <Link to='/service'><button type="button" className="btn btn-primary btn-lg">Discover More</button></Link>

           </div>
        </section>
        
        <div style={{backgroundColor:'rgb(245, 248, 248)'}}>
       <Container>
       <section className='service3' >
            <div className='service4'>
            <h2>What We Do</h2>
            <br></br>
          <p>Our efficient bookkeeping services encompass meticulous management of daily transactions and expense tracking. This ensures that businesses maintain accurate financial records, enabling easier decision-making. By delegating these tasks to us, you can focus on core business activities without financial distractions.</p>
          <div className="ps-0 px-sm-5">
            <p className='customParagraph'>We meticulously track and record all expenses, day-to-day vouchers, and accounting bank statements.</p>
           <p className='customParagraph'>With the use of Shadobooks ERP software ensures automatic generation of detailed financial reports.</p>
           <p className='customParagraph'>We also assist in invoicing, accounts payable, accounts receivable, tax management, and inventory control.</p>
           <p className='customParagraph'>We provide a range of reports such as balance sheets, profit and loss statements, and tax reports.</p>
           <p className='customParagraph'>Businesses can access their financial data from anywhere, at any time, and on any device.</p>
          </div>
            </div>
            <div className='service5'>
            <h2>Secure Your Business Future with Ease!</h2>
           <p>Don’t let paperwork slow you down. Discover stress-free management solutions.</p>
            <Link to='/contact'> <button type="button" className="btn btn-primary btn-lg">Contact Us</button> </Link>
            </div>
        </section>
       </Container>
        
       <br />  <br />
        </div>
        
          <Formdetails />
      </>
      
    );
  }
  
  export default Completebookkeeping;