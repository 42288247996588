import style from "./Hero.module.css"; //SHB005
import { Col, Container, Row } from "react-bootstrap";
import affordable from '../../assets/Home/affordableicon.png'
import expert from '../../assets/Home/experticon.png'
import allinone from '../../assets/Home/allinouticon.png'
import { Link } from "react-router-dom";

function Hero() {
  return (
    <>
      <section>
        <div className={`${style.hero1}`} data-aos="fade-down">
          <div className={`${style.hero22} mb-5`}>
            <h1>Your All-in-One Solution Accounting & Tax Filing</h1>
            <p className="pt-3">
            Streamline Your Business Finances Today! save time and reduce stress with our comprehensive accounting solutions.
            </p>
            <Link to="/contact">
            <button type="button" className="btn btn-primary btn-lg">
              {/* <GoArrowRight /> */}
              Get Started Now
            </button></Link>
          </div>
        </div>
      </section>

      {/* <section className="card-hero pb-5">
        <Container>
          <Row className="card-hero-margin-top">
           <div>
            <Col md={6} lg={4}>
              <div className="card-flex px-4 py-4 mt-4 mt-md-4">
                <div>
                  <img src={allinone} alt='all-in-one-platform' className="me-3" style={{width:'70px'}} />
                </div>
                <div>
                  <h3>
                    <b>All-in-One Platform</b>
                  </h3>
                  <p className="card-content">
                  From company registration to tax filing, manage everything in one place.
                  </p>

                  <div className="pt-3">
                    <button className="learn-more py-3 px-4">
                      <b>Learn More</b>
                    </button>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={6} lg={4}>
              <div className="card-flex px-4 py-4 mt-4 mt-md-4">
                <div>
                <img src={affordable} alt='all-in-one-platform' className="me-3" style={{width:'70px'}} />
                </div>
                <div>
                  <h3>
                    <b>Affordable Solutions</b>
                  </h3>
                  <p className="card-content">
                  Transparent pricing tailored for small to medium-sized businesses.
                  </p>

                  <div className="pt-3">
                    <button className="learn-more py-3 px-4">
                      <b>Learn More</b>
                    </button>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={12} lg={4}>
              <div className="card-flex px-4 py-4 mt-4">
                <div>
                <img src={expert} alt='all-in-one-platform' className="me-3" style={{width:'70px'}} />
                </div>
                <div>
                  <h3>
                    <b>Expert Team</b>
                  </h3>
                  <p className="card-content">
                  Experienced professionals committed to helping you succeed
                  </p>

                  <div className="pt-3">
                    <button className="learn-more py-3 px-4">
                      <b>Learn More</b>
                    </button>
                  </div>
                </div>
              </div>
            </Col>
            </div>
          </Row>
        </Container>
      </section> */}

<section className={`${style.cardhero} pb-5`}>
  <Container>
    <Row className={`${style.cardheromargintop}`}>
      {[
        {
          img: allinone,
          title: 'All-in-One Platform',
          content: 'From company registration to tax filing, manage everything in one place.',
        },
        {
          img: affordable,
          title: 'Affordable Solutions',
          content: 'Transparent pricing tailored for small to medium-sized businesses.',
        },
        {
          img: expert,
          title: 'Expert Team',
          content: 'Experienced professionals committed to helping you succeed.',
        },
      ].map((item, index) => (
        <Col md={12} lg={4} key={index} className="mt-3">
          <div className={`${style.cardflex} d-flex flex-column px-4 pt-4 mt-5`}>
            <div className="d-flex align-items-start">
              <img src={item.img} alt={item.title} className="me-3" style={{width:'70px'}} />
              <div>
                <h3><b>{item.title}</b></h3>
                <p className={`${style.cardcontent}`}>{item.content}</p>
              </div>
            </div>
            {/* <div className="mt-auto buttons">
              <button className="learn-more py-3 px-4"><b>Learn More</b></button>
            </div> */}
          </div>
        </Col>
      ))}
    </Row>
  </Container>
</section>

    </>
  );
}

export default Hero;
