import React from "react";
import style from "../../modules/PartnerShip.module.css";
import { Col, Container, Row } from "react-bootstrap";
import partner1 from "../../assets/Home/partnership/athena.webp";
import partner2 from "../../assets/Home/partnership/flowsar.webp";
import partner3 from "../../assets/Home/partnership/iniya_builders.webp";
import partner4 from "../../assets/Home/partnership/tax_mobile-removebg-preview.webp";
import partner5 from "../../assets/Home/partnership/tetra.webp";
import partner6 from "../../assets/Home/partnership/thai_design.webp";

const PartnerShip = () => {
  return (
    <section className={`${style.partner} py-5`}>
      <Container>
        <div className={`${style.partnership}`}>
          <h2>Our Trusted Partners</h2>
        </div>
        <Row className="pt-4">
          <Col md={4} className={`${style.partnerCol} px-0`}>
            <div className={style.partnerImageWrapper}>
              <img className={style.partnerImage} src={partner1} alt="athena" />
            </div>
          </Col>
          <Col md={4} className={`${style.partnerCol} px-0`}>
            <div className={style.partnerImageWrapper}>
              <img className={style.partnerImage} src={partner2} alt="flowsar" />
            </div>
          </Col>
          <Col md={4} className={`${style.partnerCol} px-0`}>
            <div className={style.partnerImageWrapper}>
              <img className={style.partnerImage} src={partner3} alt="iniya_builders" />
            </div>
          </Col>
          <Col md={4} className={`${style.partnerCol} px-0`}>
            <div className={style.partnerImageWrapper}>
              <img className={style.partnerImage} src={partner4} alt="tax_mobile" style={{width:"300px"}}/>
            </div>
          </Col>
          <Col md={4} className={`${style.partnerCol} px-0`}>
            <div className={style.partnerImageWrapper}>
              <img className={style.partnerImage} src={partner5} alt="tetra" />
            </div>
          </Col>
          <Col md={4} className={`${style.partnerCol} px-0`}>
            <div className={style.partnerImageWrapper}>
              <img className={style.partnerImage} src={partner6} alt="thai_design" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default PartnerShip;
