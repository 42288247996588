

import { Container} from 'react-bootstrap';
import './Service.css'
// import { TiTick } from "react-icons/ti";
import { Link } from 'react-router-dom';
import Formdetails from '../Form/Formdetails';


function Auditingservices() {
    return (
      <>
        <section className="Auditingservices" >

            <div className='service2'>
           <p>Tax India Tax</p>
           <h1>Accounting & Auditing Services</h1>
           <p>Ensure accuracy and transparency with our thorough accounting and auditing processes.</p>
           <Link to='/service'><button type="button" className="btn btn-primary btn-lg">Discover More</button></Link>

           </div>
        </section>
        
        <div style={{backgroundColor:'rgb(245, 248, 248)'}}>
       <Container>
       <section className='service3' >
            <div className='service4'>
            <h2>What We Do</h2>
            <br></br>
          <p>Our internal auditing identifies inefficiencies and enhances operational integrity. By conducting regular audits, we uncover areas of risk and maintaining compliance with regulations. Our proactive approach fosters trust and transparency, which is important for investor confidence and business reputation.</p>
          <div className="ps-0 px-sm-5">
            <p className='customParagraph'>Complete suite of accounting and auditing services tailored to your business needs.</p>
           <p className='customParagraph'>Risk-based approach for tailored audit scopes, ensuring efficiency and value.</p>
           <p className='customParagraph'>Expertise in various audit types: bank audit, statutory audit, internal audit.</p>
           <p className='customParagraph'>Final statement preparation and signing.</p>
           <p className='customParagraph'>UDIN generation for professional compliance.</p>
           <p className='customParagraph'>Audits available for both yearly and quarterly periods.</p>
           </div>
            </div>
            <div className='service5'>
            <h2>Secure Your Business Future with Ease!</h2>
           <p>Don’t let paperwork slow you down. Discover stress-free management solutions.</p>
            <Link to='/contact'> <button type="button" className="btn btn-primary btn-lg">Contact Us</button> </Link>
            </div>
        </section>
       </Container>
       <br />  <br />
        
        </div>

          <Formdetails />
      </>
      
    );
  }
  
  export default Auditingservices;