import { Col, Container, Row } from "react-bootstrap";
import { FaEnvelope, FaPhone } from "react-icons/fa6";
import { ImLocation2 } from "react-icons/im";
import style from "../../modules/Contactinformation.module.css";
import { Link } from "react-router-dom";

function Contactinformation() {
  return (
    <>
    <section className={`${style.inforbg} pt-4 pb-3`}>

        <Container>
          <Row>
            <Col md={4} className="my-3 mt-md-0">
              <div className={`${style.call}`}>
                <div>
                  <ImLocation2 className="faclock me-2" size={30}/>
                </div>
                <div className={`${style.contactfont}`}>
                  <p className="mb-0">Stew&Jones Building</p>
                  <p className="mb-0">
                    <b>Tvm-Ngl Main Road</b>
                  </p>
                </div>
              </div>
            </Col>

            <Col md={4}  className="my-3 mt-md-0">
              <div className={`${style.call}`}>
                <div>
                  <FaPhone className="faclock me-2" size={30} />
                </div>
                <div className={`${style.contactfont}`}>
                  <p className="mb-0">Phone Number</p>
                  <p className="mb-0">
                    <b><Link to='tel:+919087000123' style={{textDecoration:'none', color:'white'}}>(+91) 9087-000-123</Link></b>
                  </p>
                </div>
              </div>
            </Col>

            <Col md={4} className="my-3 mt-md-0">
              <div className={`${style.call}`}>
                <div>
                  <FaEnvelope className="faclock me-2" size={30}/>
                </div>
                <div className={`${style.contactfont}`}>
                  <p className="mb-0">Contact Email</p>
                  <p className="mb-0">
                    <b><Link to='mailto:stewjoneassociates@gmail.com' style={{textDecoration:'none', color:'white'}}>stewjoneassociates@gmail.com</Link></b>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
    </section>
    </>
  );
}

export default Contactinformation;
