import { Container} from 'react-bootstrap';
import './Service.css'
// import { TiTick } from "react-icons/ti";
import { Link } from 'react-router-dom';
import Formdetails from '../Form/Formdetails';


function CompanyRegistration() {
    return (
      <>
        <section className="companyregistration">
            <div className='service2'>
           <p>Tax India Tax</p>
           <h1>Company Registration and Filing Services</h1>
           <p>Set up your business seamlessly with our expert guidance.</p>
           <Link to='/service'><button type="button" className="btn btn-primary btn-lg">Discover More</button></Link>

           </div>
        </section>
       
        <div style={{backgroundColor:'rgb(245, 248, 248)'}}>
       <Container>
       <section className='service3' >
            <div className='service4'>
            <h2>What We Do</h2>
            <br></br>
           <p>Registering a company is the first crucial step toward establishing your legal identity in the market. With our tailored solutions, we ensure every business type will finds the fitting registration model. You will benefit from limited liability, easier access to funding, and a recognized business presence. We simplify the process so you can focus on what matter most - your core business activities.
           </p>
           <div className="ps-0 px-sm-5">
           <p className='customParagraph'>We register all types of companies, handle annual filings, MCA-related filings, and manage other tax compliances.
           </p>
           <p className='customParagraph'>Receive tailored solutions and expert advice from our team with in-depth knowledge of business laws and regulations.
           </p>
           <p className='customParagraph'>We ensure your tax obligations are met, helping you avoid penalties and maintain smooth operations.
           </p>
           <p className='customParagraph'>We manage your company's annual filings to keep you up-to-date with legal requirements.
           </p>
           <p className='customParagraph'>Whether you're a startup or an established enterprise, we simplify your registration and filing processes.
           </p>
           <p className='customParagraph'>Avoid penalties and focus on business growth while we manage your registration and compliance needs.
           </p></div>
            </div>
            <div className='service5'>
            <h2>Secure Your Business Future with Ease!</h2>
           <p>Don’t let paperwork slow you down. Discover stress-free management solutions.</p>
            <Link to='/contact'> <button type="button" className="btn btn-primary btn-lg">Contact Us</button> </Link>
            </div>
        </section>
       </Container>
        
       <br />  <br />
        </div>
        
          <Formdetails />
      </>
      
    );
  }
  
  export default CompanyRegistration;