import React from "react";
import style from "../../modules/OurHistory.module.css";
import { Container, Row, Col } from "react-bootstrap";

const OurHistory = () => {
  return (
    <>
      <section>
        <Container>
          <div className={` ${style.section2row}`}>
            <Row>
              <Col lg={6} md={6} xl={6} xxl={6}>
                <h2 className={`${style.ourhistoryhead}`}>
                  <b>Company's Journey</b>
                </h2>
              </Col>
              <Col lg={6} md={6} xl={6} xxl={6}>
                <div className={` ${style.historypara}`}>
                  <p>
                    Stew & Jones Accountants was established in 2010 & its
                    associate professional firm, Tax India Tax, is now in India.
                    We offer comprehensive accounting solutions including
                    audits, accounting software and tax filing etc. We brought
                    with us a commitment to professionalism, integrity, and
                    client satisfaction. Over the years, we have navigated the
                    ever-evolving financial regulations, ensuring that our
                    clients always remain compliant while optimizing their
                    financial health. Our journey reflects our adaptability,
                    resilience, and unwavering dedication to our clients’
                    success.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
};

export default OurHistory;
