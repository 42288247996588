import React, { useState } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import style from "../../modules/Contact.module.css";
import { ImLocation2 } from "react-icons/im";
import { FaEnvelope, FaPhone } from "react-icons/fa6";
import { TbMessage2Check } from "react-icons/tb";
import emailjs from '@emailjs/browser';
import { Link } from "react-router-dom";

function Contactpage() {
  const [formData, setFormData] = useState({
    name: '',
    contact: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    const templateParams = {
      from_name: formData.name,
      contact_number: formData.contact,
      from_email: formData.email,
      subject: formData.subject,
      message: formData.message
    };

    emailjs.send('service_gx0vpji', 'template_1buu9s7', templateParams, 'HvYc1Z8qYrBXBSnfk')
      .then((result) => {
        console.log('Email successfully sent!', result.text);
        setFormData({ name: '', contact: '', email: '', subject: '', message: '' });
      }, (error) => {
        console.log('Failed to send email:', error.text);
      });
  };

  return (
    <>
      <section>
        <section className={`${style.contact1}`}>
          <div className={style.contact2}>
            <h1>Contact Us</h1>
          </div>
        </section>

        <section className={`${style.contactbg} pb-5`}>
          <Container>
            <Row className="pt-5">
              <Col md={5} lg={5}>
                <div>
                  <p>Get in touch</p>
                  <h1>How can we help?</h1>
                  <p>Whatever your business needs, expert help is just a click away.</p>
                </div>
                <Row>
                  <Col md={12}>
                    <div className="d-flex align-center p-3">
                      <div>
                        <ImLocation2 className="faclock me-2" />
                      </div>
                      <div>
                        <p className="mb-0">Stew&Jones Building</p>
                        <p className="mb-0">
                          <b>Tvm-Ngl Main Road</b>
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="d-flex align-center p-3">
                      <div>
                        <FaPhone className="faclock me-2" />
                      </div>
                      <div>
                        <p className="mb-0">Phone Number</p>
                        <p className="mb-0">
                          <b><Link to='tel:+919087000123' style={{textDecoration:'none', color:'black'}}>(+91) 9087-000-123</Link></b>
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="d-flex align-center p-3">
                      <div>
                        <FaEnvelope className="faclock me-2" />
                      </div>
                      <div>
                        <p className="mb-0">Contact Email</p>
                        <p className="mb-0">
                          <b><Link to='mailto:stewjoneassociates@gmail.com' style={{textDecoration:'none', color:'black'}}>stewjoneassociates@gmail.com</Link></b>
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md={7} lg={7} className="pt-4 pt-md-0">
                <div className={`${style.contactform} p-4`}>
                  <Form onSubmit={sendEmail}>
                    <Form.Group className="mb-3" controlId="formGroupName">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupcontact">
                      <Form.Label>Contact Number</Form.Label>
                      <Form.Control
                        type="tel"
                        name="contact"
                        value={formData.contact}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupEmail">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupSubject">
                      <Form.Label>Subject</Form.Label>
                      <Form.Control
                        type="text"
                        name="subject"
                        value={formData.subject}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupmessage">
                      <Form.Label>Message</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows="4"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    <div className="d-grid gap-2">
                      <button className="btn btn-primary" type="submit">
                        Submit <TbMessage2Check />
                      </button>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </section>
    </>
  );
}

export default Contactpage;
