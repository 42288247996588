import React from 'react'
import style from '../../modules/Testimonials.module.css'
import { Card, Carousel, Col, Row } from 'react-bootstrap'
import { FaStar, FaStarHalf } from 'react-icons/fa'

const Testimonials = () => {
  return (
    <section className='mb-5'>
        <div className='container mt-5'>
            <h2 className='mb-5' style={{ textAlign:"center" }}>
            <b> What Our Clients Say </b>
            </h2>

      <Carousel controls={false}>
        <Carousel.Item>
          <Row className="justify-content-center">
            <Col md={6} className={`${style.testimrg} mt-4 mt-md-0`}>
              <Card className='h-100 d-flex flex-column align-items-center'>
                <div className={`${style.personImage} py-4 px-5 w-100`}>
                    <div className='ms-3 text-center'>
                      <Card.Title className='mb-3'><b><span className={`${style.names}`}>Berjin Babu</span></b></Card.Title>
                      <p className={`${style.start}`}><FaStar /> <FaStar /> <FaStar /> <FaStar /> <FaStarHalf /></p>
                    </div>
                
                <Card.Body className='d-flex flex-column justify-content-between w-100'>
                  <div className={`${style.testimonialbody} p-4`}>
                    <p className={style.personContent}>
                      <i>Tax transformed the way I handle my accounting. Their all-in-one solution saved me both time and money.</i>
                    </p>
                  </div>
                  <Card.Subtitle className="text-center mt-4" style={{ color:'black', fontSize:'18px'}}>- Founder of Iniya Builders</Card.Subtitle>
                </Card.Body>
                </div>
              </Card>
            </Col>

            <Col md={6} className={`${style.testimrg} mt-4 mt-md-0`}>
              <Card className='h-100 d-flex flex-column align-items-center'>
                <div className={`${style.personImage} py-4 px-5 w-100`}>
                    <div className='ms-3 text-center'>
                      <Card.Title className='mb-3'><b><span className={`${style.names}`}>Joseph Shibu</span></b></Card.Title>
                      <p className={`${style.start}`}><FaStar /> <FaStar /> <FaStar /> <FaStar /> <FaStar /></p>
                    </div>                
             
                <Card.Body className='d-flex flex-column justify-content-between w-100'>
                  <div className={`${style.testimonialbody} p-4`}>
                    <p className={style.personContent}>
                      <i>The team provided exceptional service during our GST registration. They made the entire process smooth and hassle-free.</i>
                    </p>
                  </div>
                  <Card.Subtitle className="text-center mt-4" style={{ color:'black', fontSize:'18px'}}>- CEO of Athena Designers</Card.Subtitle>
                </Card.Body>
                </div>
              </Card>
            </Col>
          </Row>
          </Carousel.Item>

          <Carousel.Item>
          <Row>          
            <Col md={6} className={`${style.testimrg} mt-4 mt-md-0`}>
              <Card className='h-100 d-flex flex-column align-items-center'>
                <div className={`${style.personImage} py-4 px-5 w-100`}>
                    <div className='ms-3 text-center'>
                      <Card.Title className='mb-3'><b><span className={`${style.names}`}>Sargunam Rajendran</span></b></Card.Title>
                      <p className={`${style.start}`}><FaStar /> <FaStar /> <FaStar /> <FaStar/></p>
                    </div>
                
                <Card.Body className='d-flex flex-column justify-content-between w-100'>
                  <div className={`${style.testimonialbody} p-4`}>
                    <p className={style.personContent}>
                      <i>The custom ERP software has improved our bookkeeping efficiency significantly. I can’t imagine managing without it!</i>
                    </p>
                  </div>
                  <Card.Subtitle className="text-center mt-4" style={{ color:'black', fontSize:'18px'}}>- Owner of Flowsar</Card.Subtitle>
                </Card.Body>
                </div>
              </Card>
            </Col>        

            <Col md={6} className={`${style.testimrg} mt-4 mt-md-0`}>
              <Card className='h-100 d-flex flex-column align-items-center'>
                <div className={`${style.personImage} py-4 px-5 w-100`}>
                    <div className='ms-3 text-center'>
                      <Card.Title className='mb-3'><b><span className={`${style.names}`}>Ponraj Rajendran</span></b></Card.Title>
                      <p className={`${style.start}`}><FaStar /> <FaStar /> <FaStar /> <FaStar /> <FaStarHalf /></p>
                    </div>
                
                <Card.Body className='d-flex flex-column justify-content-between w-100'>
                  <div className={`${style.testimonialbody} p-4`}>
                    <p className={style.personContent}>
                      <i>Their efficiency and attention to detail allowed us to focus on growing our business without any worries.</i>
                    </p>
                  </div>
                  <Card.Subtitle className="text-center mt-4" style={{ color:'black', fontSize:'18px'}}>- Founder of Thai Design</Card.Subtitle>
                </Card.Body>
                </div>
              </Card>
            </Col>
          </Row>            
        </Carousel.Item>

        <Carousel.Item>
          <Row>   
            <Col md={6} className={`${style.testimrg} mt-4 mt-md-0`}>
              <Card className='h-100 d-flex flex-column align-items-center'>
                <div className={`${style.personImage} py-4 px-5 w-100`}>
                    <div className='ms-3 text-center'>
                      <Card.Title className='mb-3'><b><span className={`${style.names}`}>Christopher</span></b></Card.Title>
                      <p className={`${style.start}`}><FaStar /> <FaStar /> <FaStar /> <FaStar /> <FaStarHalf /></p>
                    </div>                
             
                <Card.Body className='d-flex flex-column justify-content-between w-100'>
                  <div className={`${style.testimonialbody} p-4`}>
                    <p className={style.personContent}>
                      <i>Their team handled our TDS filing with exceptional precision, ensuring we stayed compliant without any stress.</i>
                    </p>
                  </div>
                  <Card.Subtitle className="text-center mt-4" style={{ color:'black', fontSize:'18px'}}>- Partner at DD Associates</Card.Subtitle>
                </Card.Body>
                </div>
              </Card>
            </Col>

            <Col md={6} className={`${style.testimrg} mt-4 mt-md-0`}>
              <Card className='h-100 d-flex flex-column align-items-center'>
                <div className={`${style.personImage} py-4 px-5 w-100`}>
                    <div className='ms-3 text-center'>
                      <Card.Title className='mb-3'><b><span className={`${style.names}`}>Chellaiah Jebaraj</span></b></Card.Title>
                      <p className={`${style.start}`}><FaStar /> <FaStar /> <FaStar /> <FaStar /></p>
                    </div>
                
                <Card.Body className='d-flex flex-column justify-content-between w-100'>
                  <div className={`${style.testimonialbody} p-4`}>
                    <p className={style.personContent}>
                      <i>With their help, GST registration was seamless, and their bookkeeping services have kept our finances crystal clear.</i>
                    </p>
                  </div>
                  <Card.Subtitle className="text-center mt-4" style={{ color:'black', fontSize:'18px'}}>- MD of Tetra Agencies</Card.Subtitle>
                </Card.Body>
                </div>
              </Card>
            </Col>           
          </Row>            
        </Carousel.Item>              
      </Carousel>
      </div>
    </section>
  )
}

export default Testimonials
