import React from "react";
import style from './HomeOurService.module.css'; /* SHB005 */
import { Card, Col, Row } from "react-bootstrap";
import { OurServicedata } from "../../utils/OurServicesdata";
import { Link } from "react-router-dom";

const HomeOurService = () => {
  return (
    <>
      <section className={`${style.ourservicebg} py-5`}>
        <div className={style.accounthead} style={{ textAlign: "center" }}>
          <p style={{ color: "#008dd4" }} className="mt-1 pt-1 pt-md-5">
            <b>Our Services</b>
          </p>

          <h2>
            <b>Stew and Jones Associates: Simplifying Your Accounting</b>
          </h2>
          <p className={`${style.accountheadp} pt-3 px-2`}>
            Experience a new standard in accounting services with Stew and Jones
            Associates. Your business deserves the best!
          </p>
        </div>

        <div className="">
          <>
            <Row className="mx-5">
              {OurServicedata.map((service) => (
                <Col
                  md={6}
                  lg={4}
                  key={service.id}
                  className="mt-4 mt-md-4 mt-lg-4"
                >
                  <Link to={service.slug} style={{ textDecoration: "none" }}>
                    <Card className={`${style.servicecard} h-100`} data-aos="fade-up">
                      <div className={style.zoomcontainer}>
                        <Card.Img
                          variant="top"
                          src={service.image}
                          className={`${style.zoomimage}`}
                          alt={service.heading}
                          style={{ width: "100%" }}
                        />
                      </div>
                      <Card.Body className="d-flex flex-column">
                        <Card.Title>{service.heading}</Card.Title>
                        <Card.Text className="pt-3 flex-grow-1">
                          {service.content}
                        </Card.Text>
                        <div>
                          {/* Any additional content like a button can go here */}
                        </div>
                      </Card.Body>
                      <div>
                        <button className={`${style.arrowright} px-4 pb-2`}>
                          &rarr;
                        </button>
                      </div>
                    </Card>
                  </Link>
                </Col>
              ))}
            </Row>
          </>
        </div>
      </section>
    </>
  );
};

export default HomeOurService;
