import React from 'react'
import accountingteamimg from "../../assets/Home/accounting-team.jpg";
import { Col, Row } from 'react-bootstrap'
import style from './Partner.module.css'

const Partner = () => {
  return (
    <>
      <section className={`${style.partnerbg} pt-5`}>
        <Row className="mx-1 py-5">
              <div className={style.partnerhead}>
                <h2>
                  <b>Your Trusted Partner in Accounting </b>
                </h2>

                <p className={`${style.partnercontent} my-3`}>
                    At Stew and Jones Associates, we understand the challenges entrepreneurs and finance 
                    professionals face. Our mission is to simplify your financial responsibilities, allowing 
                    you to focus on what you do best: running your business.
                </p>
              </div>
              
          <Col md={12} lg={6}>
            <div className="px-5 py-3">

              <div className={`${style.partnerlist} px-5 py-4`}>
                
                    <h5>Expert Support</h5>
                    <p className={style.ideacontent}>Our team of professionals has the knowledge and experience to guide you through each step.</p>
          
              </div>

            <div className={`${style.partnerlist} px-5 py-4 mt-4`}>             
                    <h5>Unified Solutions</h5>
                    <p className={style.ideacontent}>No need to juggle multiple software—everything you need is available in one complete platform.</p>
               
            </div>

            <div className={`${style.partnerlist} px-5 py-4 mt-4`}>
            
                    <h5>Client-Centric Approach</h5>
                    <p className={style.ideacontent}>We prioritize your needs and work diligently to provide tailored services.</p>
             
            </div>
            
            </div>
              
          </Col>

          <Col md={12} lg={6}>
            <div className='py-3'>
              <img
                src={accountingteamimg}
                alt="Accounting-team"
                style={{  height: "540px", width: "100%" }}
              />
            </div>
          </Col>
        </Row>
        {/* <div className='pb-5'></div> */}
      </section>
    </>
  )
}

export default Partner
