import React from "react";
import style from "../../modules/Callbackabout.module.css";
import { Row, Col } from "react-bootstrap";
import {Link} from "react-router-dom"

const Callbackabout = () => {
  return (
    <section className={`${style.mainrow1} mb-5`}>
      
      <div className={style.mainrow2}>
        
        <Col lg={9} className="mx-auto">
            <div className={style.headingimage}>
              <h1 className={style.heading1}><b>Are you ready to take the next step towards business success?</b> </h1>
              <p className={style.paragraphcallback}>
                At Stew and Jones Associates, we're here to make your financial journey seamless and stress-free. Our experienced team will provide you with all-in-one solutions suitable for your business. Together, let's simplify your financial responsibilities and pave the way for your growth!
              </p>
              <div className={style.button}>
             <Link to="/contact"> <button type="button" className=" callbackbtn btn btn-primary btn-lg">
                Contact Us Now
              </button></Link>

              </div>
            </div>
          </Col>
        
      </div>
      
    </section>
  );
};

export default Callbackabout;
