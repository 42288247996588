import { Container} from 'react-bootstrap';
import './Service.css'
import { Link } from 'react-router-dom';
import Formdetails from '../Form/Formdetails';


function Udyamregistration() {
    return (
      <>
        <section className="udyamregistration">
            <div className='service2'>
           <p>Tax India Tax</p>
           <h1>Udyam Registration Services for SMEs</h1>
           <p>Benefit from government schemes designed for small businesses.</p>
           <Link to='/service'><button type="button" className="btn btn-primary btn-lg">Discover More</button></Link>

           </div>
        </section>
       
        <div style={{backgroundColor:'rgb(245, 248, 248)'}}>
       <Container>
       <section className='service3' >
            <div className='service4'>
            <h2>What We Do</h2>
            <br></br>
            <div className="ps-0 px-sm-5">
              <p className='customParagraph'>By registering under Udyam, we enhance your access to government benefits and support. Our process classifies enterprises based on investments and turnover, making approvals and assistance easier for us. A distinct Udyam number is essential for your business to availing various schemes aimed at fostering for your business growth.</p>
              <p className='customParagraph'>We assist small and medium enterprises (SMEs) in ensuring their business operations run smoothly.</p>
              <p className='customParagraph'>In Udyam registration, we classify enterprises based on their investment and turnover.</p>
              <p className='customParagraph'>We guide entrepreneurs through the online application process for MSME Udyam Registration.</p>
              <p className='customParagraph'>Upon successful completion, the enterprise receives a permanent Udyam Registration Number and an e-certificate known as the Udyam Registration Certificate.</p>
           
            </div>
            </div>
            <div className='service5'>
            <h2>Secure Your Business Future with Ease!</h2>
           <p>Don’t let paperwork slow you down. Discover stress-free management solutions.</p>
            <Link to='/contact'> <button type="button" className="btn btn-primary btn-lg">Contact Us</button> </Link>
            </div>
        </section>
       </Container>
       <br />  <br />
        
        </div>
        
          <Formdetails />
      </>
      
    );
  }
  
  export default Udyamregistration;