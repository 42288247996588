import React from 'react'
import style from '../../modules/SingleScreen.module.css'

const SingleServiceHero = () => {
  return (
    <>
      <section>
        <div className={`${style.hero1}`} data-aos="fade-down">
          <div className={`${style.hero2} mb-5`}>
            <h1><b>Services</b></h1>
          </div>
        </div>
      </section>
    </>
  )
}

export default SingleServiceHero
