import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import style from "../../modules/CalltoAction.module.css";
import { Link } from "react-router-dom";

const CalltoAction = () => {
  return (
    <>
    <section className={`${style.calltoaction} py-5`}>
        <div className={``} >
          <Container>
            <Row>
              <Col
                md={6}
                className="d-flex align-items-center justify-content-center"
              >
                <div className={`${style.heading}`}>
                  <h2>
                    <b> Ready to Simplify Your Accounting? </b>
                  </h2>
                </div>
              </Col>

              <Col md={6}>
                <div>
                  <p className={`${style.content} pt-2`}>
                    Don’t let your finances overwhelm you. Join the hundreds of
                    satisfied clients and discover how Stew and Jones Associates
                    can help you thrive.
                  </p>

                  <Link to='/contact'> <button className={`${style.contactUs} py-3 px-4 my-3`}>
                    <b>Contact Us Today</b>
                  </button> </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
};

export default CalltoAction;
