import { Container } from "react-bootstrap";
import "./Service.css";
import { Link } from "react-router-dom";
import Formdetails from "../Form/Formdetails";

function Digitalsignature() {
  return (
    <>
      <section className="digital">
        <div className="service2">
          <p>Tax India Tax</p>
          <h1>Digital Signature Services</h1>
          <p>
            Secure your online transactions and documents with our trusted
            digital signature solutions.
          </p>
          <Link to='/service'><button type="button" className="btn btn-primary btn-lg">Discover More</button></Link>
        </div>
      </section>

      <div style={{ backgroundColor: "rgb(245, 248, 248)" }}>
        <Container>
          <section className="service3">
            <div className="service4">
              <h2>What We Do</h2>
              <br></br>
              <p>
                Our digital signature certificate offers a secure way to
                validate identity and authenticate documents electronically. By
                eliminating the need of physical signatures, we help businesses
                operate more efficiently in the digital realm. We make it easy
                to ensure compliance with legal requirements and make business
                transactions smooth.
              </p>
              <div className="ps-0 px-sm-5">
                <p className='customParagraph'>
                  We offer both individual and tender Digital Signature
                  Certificates (DSC).
                </p>
                <p className='customParagraph'>
                  We provide Class 2 or Class 3 digital signatures tailored to
                  your business requirements.
                </p>
                <p className='customParagraph'>
                  Digital signatures are obtained directly from the government,
                  ensuring authenticity.
                </p>
                <p className='customParagraph'>
                  No need for manual document submission or courier services;
                  the entire application process is conducted online.
                </p>
                <p className='customParagraph'>
                  All digital signatures are provided with a FIPS-compliant
                  ePass USB token, ensuring the security of your signature until
                  its validity ends.
                </p>
              </div>
            </div>
            <div className="service5">
            <h2>Secure Your Business Future with Ease!</h2>
           <p>Don’t let paperwork slow you down. Discover stress-free management solutions.</p>
            <Link to='/contact'> <button type="button" className="btn btn-primary btn-lg">Contact Us</button> </Link>
            </div>
          </section>
        </Container>
        <br />  <br />
      </div>

      <Formdetails />
    </>
  );
}

export default Digitalsignature;
