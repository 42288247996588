import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import style from "../../modules/Purposegoals.module.css";

const Purposegoals = () => {
  return (
    <>
      <section>
        <Container>
          <Row className={style.section2row}>
            <Col lg={6} md={6} xl={6} xxl={6} className={style.contentCol}>
              <div className={style.historypara}>
                <p>
                  At Stew and Jones Associates, our mission is to be your
                  all-in-one solution for accounting and tax filing. Managing
                  finances can be a daunting task for entrepreneurs and
                  financial professionals alike. Our goal is to reduce this
                  burden. We help you focus on growing your business. We also
                  facilitate financial clarity, allowing small and medium
                  enterprises (SMEs) to thrive without the constant stress of
                  accounting and compliance issues. Through transparent pricing
                  and tailored solutions, we aim to level the playing field for
                  all businesses.
                </p>
              </div>
            </Col>
            <Col lg={6} md={6} xl={6} xxl={6} className={style.headingCol}>
              <h2 className={style.ourhistoryhead}>
                <b>Purpose and Goals</b>
              </h2>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Purposegoals;
