import React from 'react'
import { Col, Container, Nav, Navbar, Row } from 'react-bootstrap'
import style from './Navbar.module.css'  /* SHB005 */
import { FaClock, FaEnvelope, FaLocationDot, FaPhone } from 'react-icons/fa6'
import logo from '../../assets/logo/logo1.png'

const Header = () => {

  return (
    <>
      <section>
        <div className={style.requestbg}>
        <Container>
          <Row className='pb-5 pb-lg-0'>
              <Col md={12} lg={8}>
                  <div className={`${style.locationflex} pt-3 my-3 mt-lg-0`}>
                    <div>
                        <FaLocationDot className={`${style.faLocationpin} me-3`}/>
                    </div>
                    <div>
                      <p className='mb-0'><a className={style.atagcssmap} href='https://maps.app.goo.gl/ucxDDX9mzUazwWQo6'>
                        No: 6/69, Stewandjones Building, Main Road, Marthandam.
                        </a>
                      </p>
                    </div>
                  </div>
              </Col>
              <Col md={12} lg={4}>
                  <div className={`${style.buttonright}`}>
                    <div>
                        <button className={`${style.requestbutton} py-3 px-0 px-sm-4`}><a className={style.atagcss} href='/contact'>REQUEST A QUOTE</a></button>
                    </div>
                  </div>
              </Col>
          </Row>
        </Container>
        </div>
      </section>

{/* Accounting Contact */}

      <section className={style.hidenavcontacts}>
        <Container>
            <Row className='pt-4 pb-2'>
              <Col md={4} lg={4}>
              <div className={style.clock}>
                            <div>
                              <FaClock className={`${style.faclock} me-2`}/>
                            </div>
                            <div className={style.contactfont}>
                              <p className='mb-0'>Contact Time</p>
                              <p className='mb-0'><b>Mon-Sat: 9.30am - 6.00pm</b></p>
                            </div>
                        </div>

                {/* <div> {`${style.}`} */}
                  {/* <h2 className='finance'>finance</h2> */}
                  {/* <img src={logo} alt="logo"  style={{width:'200px'}}/> */}
                {/* </div> */}
              </Col>

              <Col md={12} lg={8}>
                <div>
                    <Row>
                      <Col md={4}>
                        <div className={style.clock}>
                            <div>
                              <FaPhone className={`${style.faclock} me-2`}/>
                            </div>
                            <div className={style.contactfont}>
                              <p className='mb-0'>Office  Number</p>
                              <p className={`mb-0`}><a className={style.atagcss} href='tel:+919600705931'><b>(+91) 9600-705-931</b></a></p>
                            </div>
                        </div>
                      </Col>

                      <Col md={4}>
                        <div className={style.clock}>
                          <div>
                            <FaPhone className={`${style.faclock} me-2`}/>
                          </div>
                          <div className={style.contactfont}>
                            <p className='mb-0'>Contact Number</p>
                            <p className='mb-0'><b><a className={style.atagcss} href='tel:+919087000123'>(+91) 9087-000-123</a></b></p>
                          </div>
                        </div>
                      </Col>

                      <Col md={4}>
                        <div className={style.clock}>
                          <div>
                              <FaEnvelope className={`${style.faclock} me-2`} />
                            </div>
                            <div className={style.contactfont}>
                              <p className='mb-0'>Contact Email</p>
                              <p className={`${style.emailtext} mb-0`}><b><a className={style.atagcss} href='mailto:stewjoneassociates@gmail.com'>stewjoneassociates@gmail.com</a></b></p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                </div>
              </Col>            

          </Row>
        </Container>
        
      </section>


      {/* -------------------------------- */}

      <section className={style.navsetsticky}>
        <Navbar expand="lg" className={style.navtertiary}>
          <Container className={style.containernav}>            
          <Navbar.Brand to="/">
            <img src={logo} alt="logo"  style={{width:'200px'}}/>
          </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link href="/" className={style.navlink}>Home</Nav.Link>
                <Nav.Link  href="/about" className={style.navlink}>About</Nav.Link>
                <Nav.Link href="/service" className={style.navlink}>Services</Nav.Link>
                <Nav.Link href="/contact" className={style.navlink}>Contact</Nav.Link>           
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </section>
      <hr></hr>

{/* Accounting Contact */}

    <section className={style.navsetsticky}>

        <Navbar className={style.bgbodywhite} style={{background:"white"}}>
          <Container>
          <Navbar.Brand to="/">
            <img src={logo} alt="logo"  style={{width:'200px'}}/>
          </Navbar.Brand>
            {/* <Navbar.Brand href=""></Navbar.Brand> */}
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                <Nav.Link href="/" className={`${style.navlink} me-5`}>Home</Nav.Link>
                <Nav.Link href="/about" className={`${style.navlink} me-5`}>About</Nav.Link>
                <Nav.Link href="/service" className={`${style.navlink} me-5`}>Services</Nav.Link>
                <Nav.Link href="/contact" className={`${style.navlink}`}>Contact</Nav.Link>         
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
    </section>


    </>
  )
}

export default Header

  