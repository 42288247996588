import React from 'react'
import style from  "../../modules/Achievements.module.css"
import { Container} from "react-bootstrap";
const Achievements = () => {
    return (
        <>
         <Container>
     <section className= {style.enterpriseSection}>
  <div className={` ${style.section3main}`}>
    <div className="row justify-content-center align-items-center" >
      <div className="col-lg-12">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className={style.image}></div>
          </div>
          <div className="col-lg-6">
            <div className={style.textContent}>
              <h2 className={style.title}>Achievements</h2>
              <p className={style.description}>
                If you're an independent professional, firm, enterprise client,
                bank, or government organization, we invite you to reach out to
                our Enterprise Partnership Team. Our dedicated Enterprise Team
                is committed to collaborating with a diverse range of clients
                including professionals, banks, enterprise clients, and
                government departments to create customized solutions that meet
                specific requirements. Contact us today to explore partnership
                opportunities to work together and create value.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
     </Container>
    
        
        </>)
}
export default Achievements