
import { Container} from 'react-bootstrap';
import './Service.css'
// import { TiTick } from "react-icons/ti";
import { Link } from 'react-router-dom';
import Formdetails from '../Form/Formdetails';


function TDSreturnfiling() {
    return (
      <>
        <section className="tdsfiling">
            <div className='service2'>
           <p>Tax India Tax</p>
           <h1>Simplify Your TDS Return Filing</h1>
           <p>Never miss a deadline again; let us handle your TDS filings.</p>
           <Link to='/service'><button type="button" className="btn btn-primary btn-lg">Discover More</button></Link>

           </div>
        </section>
        
        <div style={{backgroundColor:'rgb(245, 248, 248)'}}>
       <Container>
       <section className='service3' >
            <div className='service4'>
            <h2>What We Do</h2>
            <br></br>
          <p>TDS filing requires meticulous attention to detail to ensure compliance with tax regulations and avoid penalties. With our expert guidance, we simplify this process, making it accessible for business owners to fulfill their obligations confidently. Timely filing guarantees peace of mind and upholds the integrity of the business.</p>
          <div className='ps-0 ps-sm-5'>
           <p className='customParagraph'>Simplify Your TDS Return Filing.</p>
           <p className='customParagraph'>Efficient handling of both salary and non-salary TDS submissions.</p>
           <p className='customParagraph'>Timely preparation and submission of quarterly TDS returns.</p>
           <p className='customParagraph'>Accurate filing of TDS on the income tax portal without incurring penal charges.</p>
           <p className='customParagraph'>Complete support through each step of the TDS return process.</p>
           <p className='customParagraph'>Ensuring your TDS returns are accurate and compliant with tax regulations.</p>
           <p className='customParagraph'>Helping you meet deadlines and avoid penalties with timely submissions.</p>
            </div>
            </div>
            <div className='service5'>
            <h2>Secure Your Business Future with Ease!</h2>
           <p>Don’t let paperwork slow you down. Discover stress-free management solutions.</p>
            <Link to='/contact'> <button type="button" className="btn btn-primary btn-lg">Contact Us</button> </Link>
            </div>
        </section>
       </Container>
       <br />  <br />
        
        </div>
        
          <Formdetails />
      </>
      
    );
  }
  
  export default TDSreturnfiling;