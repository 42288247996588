import React from "react";
import { Col, Row } from "react-bootstrap";
import styles from "./HomeAccounting.module.css"; // SHB005 //
import accountingteamimg from "../../assets/About/accounting sevices.webp";

const HomeAccounting = () => {
  // Destructure and store the class names from the CSS module
  const { accounting, accountHead, accountHead1, accountContent, accountingTeamImage } = styles;

  return (
    <>
      <section className={accounting}>
        <Row className="mx-1">
          <Col md={12} lg={6} xxl={6}>
            <div className="p-5">
              <div className={accountHead}>
                <h2 className={accountHead1}>
                  <b>Accounting Services for the Modern Entrepreneur</b>
                </h2>

                <p className={accountContent}>
                  From certified accountants to tax specialists, we collaborate
                  seamlessly to ensure that every client receives a personalized
                  approach to their financial needs.
                </p>
              </div>
            </div>
          </Col>

          <Col md={12} lg={6}>
            <div className={accountingTeamImage}>
              <img
                src={accountingteamimg}
                alt="Accounting-team"
                style={{ width: "100%" }}
              />
            </div>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default HomeAccounting;
