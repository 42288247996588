import React from 'react'
import Hero from '../components/Hero/Hero'
import HomeOurService from '../components/HomeOurService/HomeOurService'
import Partner from '../components/Partner/Partner'
import CalltoAction from '../components/CalltoAction/CalltoAction'
// import OurClients from '../components/OurClients/OurClients'
import PartnerShip from '../components/PartnerShip/PartnerShip'
import Formdetails from '../components/Form/Formdetails'

const HomeScreen = () => {
  return (
    <>
      <Hero />
      <HomeOurService />
      <Partner />
      <CalltoAction />
      {/* <OurClients /> */}
      <PartnerShip />
      <Formdetails />
    </>
  )
}

export default HomeScreen
