import service1 from "../assets/Home/service-image/company-register.png";
import service2 from "../assets/Home/service-image/income-tax-filling.png";
import service3 from "../assets/Home/service-image/gst-registration.webp";
import service4 from "../assets/Home/service-image/bookkeeping.png";
import service5 from "../assets/Home/service-image/auditing-service.png";
import service6 from "../assets/Home/service-image/tds-filing.png";
import service7 from "../assets/Home/service-image/trademark-registration.png";
import service8 from "../assets/Home/service-image/partnership-registration.png";
import service9 from "../assets/Home/service-image/digital-signature.png";
import service10 from "../assets/Home/service-image/udyam-registration.png";
import service11 from "../assets/Home/service-image/tax-accounting.png";

export const OurServicedata = [
    {
        id: '1',
        heading: "Company Registration",
        slug: "company-registration",
        content:"Set up your business seamlessly with our expert guidance.",
        image: service1
    },
    {
        id: '2',
        heading: "Income Tax Filing",
        slug: "income-tax-filing",
        content:"Avoid penalties and ensure compliance with our efficient filing services.",
        image: service2
    },
    {
        id: '3',
        heading: "GST Registration",
        slug: "gst-registration",
        content:"Navigate the complexities of GST with our comprehensive support.",
        image: service3
    },
    {
        id: '4',
        heading: "Bookkeeping & Accounting",
        slug: "bookkeeping-accounting",
        content:"Keep your finances organized with our reliable bookkeeping services.",
        image: service4
    },
    {
        id: '5',
        heading: "Auditing Services",
        slug: "auditing-services",
    content:"Ensure accuracy and transparency with our thorough accounting & auditing processes.",
        image: service5
    },
    {
        id: '6',
        heading: "TDS Filing ",
        slug: "tds-filing",
        content:"Never miss a deadline again; let us handle your TDS filings.",
        image: service6
    },
    {
        id: '7',
        heading: "Trademark Registration",
        slug: "trademark-registration",
    content:" Protect your brand and partnerships with our registration services.",
        image: service7
    },
    {
        id: '8',
        heading: "Partnership Registration",
        slug: "partnership-registration",
    content:"Formalize your business relationships with our hassle-free partnership registration services.",
        image: service8
    },
    {
        id: '9',
        heading: "Digital Signature",
        slug: "digital-signature",
    content:"Secure your online transactions and documents with our trusted digital signature solutions.",
        image: service9
    },
    {
        id: '10',
        heading: "Udyam Registration",
        slug: "udyam-registration",
    content:"Benefit from government schemes designed for small businesses.",
        image: service10
    },
    {
        id: '11',
        heading: "Tax Accounting ERP Software",
        slug: "https://shadobooks.com/accounting-erp-software",
    content:"Tailored software solutions to fit your unique business needs.",
        image: service11
    },
];
