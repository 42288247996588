

import { Container} from 'react-bootstrap';
import './Service.css'
// import { TiTick } from "react-icons/ti";
import { Link } from 'react-router-dom';
import Formdetails from '../Form/Formdetails';


function Trademarkregistrationservices() {
    return (
      <>
        <section className="trademark">
            <div className='service2'>
           <p>Tax India Tax</p>
           <h1>Comprehensive Trademark Registration Services</h1>
           <p>Protect your brand and partnerships with our registration services.</p>
           <Link to='/service'><button type="button" className="btn btn-primary btn-lg">Discover More</button></Link>

           </div>
        </section>
        
        <div style={{backgroundColor:'rgb(245, 248, 248)'}}>
       <Container>
       <section className='service3' >
            <div className='service4'>
            <h2>What We Do</h2>
            <br></br>
          <p>At our firm, we ensure that trademark registration safeguards your brand identity and prevents unauthorized use. While this process may involve complex legalities, our expert assistance makes it manageable. By protecting your brand, we help you maintain exclusivity and strengthens your market presence, giving you confidence in your intellectual property rights.</p>
          <div className='ps-0 ps-sm-5'>
            <p className='customParagraph'>We provide registration services for all types of trademarks, including those with logos (with or without a name).</p>
           <p className='customParagraph'>Our team offers expert assistance in filing your trademark applications with all legal requirements.</p>
           <p className='customParagraph'>We handle the ongoing legal compliance requirements for your trademark to keep your brand protected.</p>
           <p className='customParagraph'>Whether you need a trademark with just a name or a full logo, we tailor our services to meet your specific needs.</p>
           <p className='customParagraph'>From filing to compliance, we manage every step, making the trademark registration process smooth and stress-free.</p>
           </div>
            </div>
            <div className='service5'>
            <h2>Secure Your Business Future with Ease!</h2>
           <p>Don’t let paperwork slow you down. Discover stress-free management solutions.</p>
            <Link to='/contact'> <button type="button" className="btn btn-primary btn-lg">Contact Us</button> </Link>
            </div>
        </section>
       </Container>
       <br />  <br />
        
        </div>
        
          <Formdetails />
      </>
      
    );
  }
  
  export default Trademarkregistrationservices;